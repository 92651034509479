import { useDevicePixelRatio } from '@vueuse/core'

export const useImageSourceReactive = (
  imageUrl: string,
  width: Ref<number>,
  height: Ref<number>
) => {
  const img = useImage()
  const { pixelRatio } = useDevicePixelRatio()
  const reactiveImageUrl = computed(() => {
    if (width.value == 0 && height.value == 0) return ''
    return (
      img(imageUrl, { width: width.value, height: height.value }) +
      `&fit=crop&dpr=${pixelRatio.value}&override=true`
    )
  })
  return { reactiveImageUrl }
}
